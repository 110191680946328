.room-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
  gap: 2rem;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.room-card-box {
  position: relative;
  width: 21rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #553f3f12;
}
.meeting-room-img {
  height: 10.4rem;
  width: 19rem;
  background-size: auto;
}
.meeting-room-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
}
.room-card-name-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.room-card-name {
  margin-top: 1rem;
  font-size: 25px;
  color: rgb(19, 19, 19);
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  width: auto;
  height: auto;
}
.room-card-status {
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid green;
  font-size: 16px;
  color: green;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin: 20px 0 0 20px;
}
.room-card-time {
  font-size: 20px;
  color: rgb(60, 60, 60);
  margin: 0;
}
.room-card-capicity {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 18px;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.room-card-btn {
  display: flex;
  justify-content: space-between;
}
.room-card-button {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  padding: 8px;
  border-radius: 10px;
  border: none;
  width: 45%;
  background-color: #313131;
  font-variant: normal;
  color: white;
}
.room-card-button:hover {
  background-color: #000000;
}
.room-details-heading-box {
  display: flex;
  align-items: center;
  margin: 1rem 2rem;
  justify-content: center;
  padding: 15px 15px;
  height: 3.5rem;
  background-color: #553f3f12;
}
.room-details-heading {
  width: 90%;
  display: flex;
  text-align: center;
}
.room-details-heading h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 30px;
  width: 90%;
}
.cross-icon-btn {
  border: none;
}
.slots-table-container {
  padding: 0 2rem;
}
.slots-input-box {
  width: 300px;
}
.room-card-box:hover {
  box-shadow: 1px 3px 5px #000;
}
.custom-modal .modal-content {
  width: 150%;
}

.offcanvas.offcanvas-start {
  width: 95%;
  height: 100vh;
  margin: 2rem;
  border-radius: 20px;
}

.booking-form-card {
  width: 600px;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #6e6e6e;
  border-radius: 15px;
  margin: auto;
  padding: 30px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 2px 5px #313131;
}
.booking-form-heading {
  font-family: "Raleway", sans-serif;
  text-decoration: none;
  font-weight: 700;
  font-size: 25px;
  float: left;
  margin: 0;
}
.roompagination {
  margin-left: 45%;
}
@media screen and (max-width: 768px) {
  .room-cards-container {
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
  }
  .room-card-box {
    width: 80%;
  }

  .meeting-room-img {
    height: auto;
    width: 100%;
  }
  .room-details-heading h1 {
    font-size: 15px;
  }
  .hisbuttonpre {
    background-color: #774181;
    margin-right: 1rem;
    color: white;
  }
  .roompagination {
    text-align: center;
  }
  .booking-form-heading {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    float: left;
  }
}

@media screen and (min-width: 300px) and (max-width: 765px) {
  .hisbuttonpre {
    background-color: #774181;
    margin-right: 1rem;
    color: white;
  }
  .roompagination {
    margin-left: 4%;
    text-align: center;
  }
  .booking-form-heading {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    float: left;
  }
}

@media screen and (max-width: 20rem) {
  .hisbuttonpre {
    background-color: #774181;
    margin-right: 1rem;
    color: white;
  }
  .roompagination {
    text-align: center;
  }
  .booking-form-heading {
    font-family: "Raleway", sans-serif;
    font-size: 10px;
    float: left;
  }
}
.slot-date-box {
  display: flex;
  gap: 1rem;
}
