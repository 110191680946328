.dash-room-container {
  padding: 0 1rem;
}

.dash-room-heading-box {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: center;
  padding: 15px 15px;
  height: 3.5rem;
  background-color: #553f3f12;
}
.room-details-heading {
  width: 100%;
  display: flex;
  text-align: center;
}

.active-booking-label {
  padding-top: 0.5rem;
  border: none;
  position: absolute;
  right: 3rem;
  font-size: 1rem;
  color: green;
  font-weight: 700;
  cursor: pointer;
}

.currentStatus::-webkit-scrollbar {
  width: 5px;
  margin: 0;
}

.currentStatus::-webkit-scrollbar-track {
  background: #7741811d;
  height: 4px;
  margin: 0;
}
.currentStatus::-webkit-scrollbar-thumb {
  background: #774181;
  height: 3px;
}
.off-canvas-title {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}
hr {
  border-bottom: 2px solid black;
}
.room-edit-btn {
  border: none;
  font-size: 1.3rem;
}
.room-edit-btn:hover {
  color: #495057;
}

.line {
  border: 1px solid #495057;
  width: 100%;
  margin: 0px;
  margin-bottom: 8px;
}
.me-2 {
  padding: 0px;
  background-color: transparent;
  font-size: 1rem;
  color: green;
  font-weight: 700;
  top: 30px;
  height: fit-content;
  position: absolute;
  right: 15px;
  margin: -13px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .dash-room-heading-box {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    justify-content: center;
    padding: 15px 15px;
    height: 3.5rem;
    background-color: #553f3f12;
  }
  .active-booking-label {
    padding-top: 0;
    right: 2rem;
    font-size: 0.8rem;
    color: green;
    font-weight: 700;
    cursor: pointer;
  }
  .off-canvas-title {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: 13px;
  }
}
@media (max-width: 420px) {
  .active-booking-label {
    padding-top: 0;
    right: 1.2rem;
    font-size: 0.6rem;
  }
}
@media (max-width: 920px) {
  .multiSelectContainer li {
    padding: 0px;
    font-size: 10px;
  }
  .chip {
    align-items: center;
    background: #0096fb;
    border-radius: 11px;
    color: #fff;
    display: inline-flex;
    font-size: 9px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;
  }
  .me-2 {
    color: green;
    font-weight: 700;
    top: 26px;
    font-size: 14px;
    height: -moz-fit-content;
    position: absolute;
    cursor: pointer;
  }

  hr {
    border-bottom: 2px solid black;
  }
}
@media (max-width: 480px) {
  .multiSelectContainer li {
    padding: 0px;
    font-size: 10px;
  }
  .chip {
    align-items: center;
    background: #0096fb;
    border-radius: 11px;
    color: #fff;
    display: inline-flex;
    font-size: 9px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 10px;
  }
  .me-2 {
    color: green;
    font-weight: 700;
    top: 26px;
    font-size: 14px;
    height: -moz-fit-content;
    position: absolute;
    cursor: pointer;
  }

  hr {
    border-bottom: 2px solid black;
  }
}

@media (max-width: 320px) {
  .me-2 {
    color: #774181;
    top: 21px;
    font-size: 10px;
    height: -moz-fit-content;
    position: absolute;
    cursor: pointer;
  }

  hr {
    border-bottom: 2px solid black;
  }
}
