.table {
  background-color: white;
  height: auto;
  width: 100%;
  box-shadow: 0px 0px 20px black;
  margin: 0%;
  color: #333333;
}
.thead {
  height: 3rem;
  width: 8rem;
  color: #333333;
  font-family: "Lato", sans-serif;
}

.day-date {
  display: flex;
  align-items: center;
}
.tesk-row {
  margin: 10px;
  background-color: rgb(255, 255, 255);
  overflow: visible;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(230, 230, 230);
  border-radius: 10px;
  opacity: 1;
  place-self: center start;
  min-width: 200px;
  max-width: 200px;
  min-height: 60px;
  width: 200px;
  height: max-content;
  z-index: 0;
  display: flex;
  color: #333333;
  font-family: "Lato", sans-serif;
  align-items: baseline;
}
.tesk-table-row {
  margin: 0px;
  margin-left: 2rem;
  background-color: rgb(255, 255, 255);
  overflow: visible;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(230, 230, 230);
  border-radius: 10px;
  opacity: 1;
  place-self: center start;
  min-width: 200px;
  max-width: 200px;
  min-height: 60px;
  width: 200px;
  height: max-content;
  z-index: 0;
  display: flex;
  align-items: center;
  color: #333333;
  font-family: "Lato", sans-serif;
}
.tesk-row1 {
  margin-top: 0.4rem;
  height: 3rem;
  width: 8rem;
  border: 1px solid #9a9a9a;
  border-radius: 10px;
  overflow: hidden;
}
.disable-tesk-row1 {
  margin-top: 0.4rem;
  height: 3rem;
  width: 8rem;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
}
.desk-name-btn {
  margin: 10px;
}
.desk-name-conatainer {
  display: flex;
  align-items: center;
}
.desk-name-and-type-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty-row {
  width: 3rem;
}
.usernameStyle {
  padding-top: 0.4rem;
  margin: 5px;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
  padding-left: 4px;
}
.desk-name-style {
  width: 70px;
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.desk-type-style {
  width: 70px;
  text-align: center;
  font-size: 12px;
  color: #666666;
  font-weight: 600;
}
.desk-sr-no {
  font-size: 14px;
  background-color: #f2f2f2;
  color: #333333;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
}
.table-desk-book-btn {
  background-color: transparent;
  border: none;
}
.book {
  margin-top: 0.4rem;
  height: 3rem;
  width: 8rem;
  border: 1px solid #9a9a9a;
  border-radius: 10px;
  color: #333333;
}
.desk-user-avatar {
  margin: 3px;
}
.disable-book {
  margin-top: 0.4rem;
  height: 3rem;
  width: 8rem;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
}
.booknameStyle {
  margin-top: 0.6rem;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.multi {
  width: 100%;
  margin-top: 0.5rem;
}
.repeat {
  height: 2rem;
  width: 20rem;
  margin: 1rem 0px 0px 1rem;
  display: flex;
}
.repeat-booking-box {
  height: 2rem;
  width: 20rem;
  margin: 2rem 0px 0px 1rem;
  display: flex;
}
.repeat-booking-optins {
  font-size: 14px;
  margin-top: 5px;
}
.repeat-weeks {
  height: 2rem;
  width: 20rem;
  margin: 2rem 0px 0px 1rem;
  display: flex;
}
.button {
  height: 3rem;
  width: 12rem;
  color: #333333;
  margin: 1rem 0rem 0rem 18rem;
}
.main {
  width: 95%;
  margin: auto;
}

.day {
  height: 1rem;
  width: 6rem;
  margin-top: 5px;
}
.date {
  height: 1rem;
  width: 6rem;
}
.location {
  height: 2.5rem;
  padding: 6px;
  width: 100%;
  border: 1px solid rgb(255, 138, 0);
  border-radius: 5px;
}

.bookname {
  margin-top: 10px;
}
.booktitle {
  font-size: 14px;
}
.userdetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
}
th.current-date {
  color: rgb(32, 180, 155);
}
.avatar-container {
  position: relative;
  display: inline-block;
}

.avatar-container:hover .avatar {
  display: none;
}

.avatar-container:hover .close-icon {
  display: block;
}

.close-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 2px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 50%;
}
.mybooking {
  padding: 11px;
  background-color: #90ee904f;
  font-size: 1rem;
  color: green;
  font-weight: 700;
  height: -moz-fit-content;
  margin-left: 12px;
  margin-right: 30px;
  border-radius: 12px;
}
.desks {
  display: flex;
  align-items: center;
}
.disable {
  cursor: not-allowed;
  opacity: 0.5;
}

/* CSS of mybooking page */
.bookingpage {
  justify-content: center;
  align-items: center;
  margin: 12px;
  margin-right: 8rem;
  margin-left: 8rem;
  padding: 1rem;
}
.todaysheading {
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}
.todaysSubheading {
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  margin-bottom: 1.5rem;
}
.todaysbooking {
  display: flex;
  justify-content: space-around;
  margin-left: 8rem;
}
.pastbooking {
  display: flex;
  justify-content: space-around;
  margin-left: 8rem;
}
.daydatebooking {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 120px;
  font-family: "Lato", sans-serif;
  color: #333333;
  margin: 10px;
}

.bookedBy {
  display: flex;
  gap: 0.2rem;
  padding-top: 1rem;
  margin: 10px;
  width: 250px;
  align-items: center;
  font-family: "Lato", sans-serif;

  color: #333333;
}
.deskCancelButton {
  font-family: "Lato", sans-serif;

  margin: 15px;
  padding-top: 14px;
  width: 250px;
}

@media (max-width: 1440px) {
  .bookingpage {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .todaysbooking {
    margin-left: 8rem;
  }
  .pastbooking {
    margin-left: 8rem;
  }
}
@media (min-width: 1280px) and (max-width: 1280px) {
  .daydatebooking {
    width: 145px;
  }
}

@media (max-width: 1024px) {
  .todaysbooking {
    margin-left: 0rem;
    justify-content: space-between !important;
    overflow-x: scroll;
  }
  .pastbooking {
    margin-left: 0rem;
    justify-content: space-between !important;
    overflow-x: scroll;
  }
  .bookingpage {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .tesk-row {
    min-width: 180px;
  }
  .daydatebooking {
    width: 230px;
    gap: 0.3rem;
  }
}

@media only screen and (max-width: 480px) {
  .button {
    margin: 1rem 0rem 0rem 8rem;
  }
  .repeateValueSelector {
    width: 10rem;
    margin-left: 4rem;
  }
}

/*CSS for ModalComponent*/
.weekinput {
  margin: 2px;
  width: 40px;
}

/* ImportantPoint.css */

.info-icon {
  position: absolute;
  cursor: pointer;
}

.info-icon:hover .info-tooltip {
  display: block;
}

.info-tooltip {
  display: none;
  position: relative;
  background: #f0f0f0;
  padding: 2px 4px;
  border-radius: 4px;
  z-index: 1;
}

.important-point-container {
  display: inline-block;
  margin-left: 0.5rem;
}
.custom-hr {
  border: none;
  height: 1px;
  background-color: #bdbdbd;
  margin: 0;
}
.modalComponent {
  font-family: "Lato", sans-serif;
  color: #333333;
}
.deleteAllModel {
  font-family: "Lato", sans-serif;
}
.modalImage {
  width: 30%;
  height: 30%;
}
.repeateValueSelector {
  height: 0.5rem;
  width: 13rem;
  margin-left: 5rem;
}
/* Apply styles for smaller screens guest list */
@media (max-width: 480px) {
  .MuiList-root.MuiMenu-list {
    max-height: 225px;
    overflow-y: auto;
    display: block;
  }

  .MuiMenuItem-root {
    display: block;
    width: 100%;
    white-space: nowrap;
    padding: 8px 16px;
  }
  .location {
    height: auto;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .repeateValueSelector {
    width: 8rem;
  }
}
.pagination {
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 2rem;
}

.pagination-next-button,
.pagination-pre-button {
  background-color: #313131;
  color: white;
}
.left-arrow-button {
  cursor: pointer;
  margin-left: 9rem;
  border: 1px solid #b2b2b2;
  background-color: transparent;
  border-radius: 50%;
  padding: 3px;
}
.right-arrow-button {
  cursor: pointer;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  padding: 3px;
  background-color: transparent;
}
.left-arrow-button svg path,
.right-arrow-button svg path {
  color: #b2b2b2;
}
.left-arrow-button:hover svg path,
.right-arrow-button:hover svg path {
  color: #333333;
}
.edit-desk-btn-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-top: 1rem;
}
.size-suggestion {
  font-family: "Lato", sans-serif;
  color: #6b778c;
  font-size: 14px;
  margin-left: 15px;
  margin-top: 6px;
  margin-bottom: 10px;
  text-align: end;
}
.form-title-lable {
  font-family: "Lato", sans-serif;
  float: left;
  padding: 5px 0 0;
  position: relative;
  width: 170px;
  color: #6b778c;
  font-size: 18px;
  font-weight: 500;
}
.form-element-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 1px 0;
  padding: 4px 0 4px 15px;
  position: relative;
  width: 100%;
}

.chair-icon {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  color: orange;
}
.guest-name-box {
  display: flex;
  align-items: center;
}
.repeat-text {
  font-size: 14px;
  margin-top: 8px;
  margin-right: 2px;
}
.form-control-switch {
  margin-left: 10px;
}
.deskname-avatar {
  margin: 10px;
}
.delete-booking-modal {
  margin-top: 50px;
}
.delete-booking-footer {
  display: flex;
  justify-content: space-between;
}
.floor-image-remove-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #000000;
}
.show-image-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.uploaded-floor-img {
  width: 100px;
  height: 70px;
  margin-right: 10px;
}
.floor-img-box {
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
}
.view-floor-img-container {
  display: flex;
  flex-wrap: wrap;
}
.error-message-box {
  color: red;
  text-align: center;
}
.list-item-avatar {
  display: flex;
  align-items: center;
}
.book-desk-btn {
  margin-left: 1rem;
}
.desk-booking-table-container {
  overflow-x: auto;
}
.matching-desk-box {
  cursor: pointer;
}
.table-book-text {
  align-items: center;
}
.remove-selected-desk-owner-icon {
  border: 1px solid #b2b2b2;
  margin-left: 1rem;
  border-radius: 50%;
  background: transparent;
}
.remove-selected-desk-owner-icon svg {
  vertical-align: text-bottom;
}
.remove-selected-desk-owner-icon svg path {
  color: #000000;
  vertical-align: text-bottom;
}
.remove-selected-desk-owner-icon:hover svg path {
  color: #313131;
}
