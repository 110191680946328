* {
  max-width: 100%;
}
.hero-container {
  display: flex;
  padding: 2.5rem;
  padding-top: 1rem;
}
.hero-text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
}
.hero-image-box {
  width: 50%;
}
.hero-heading {
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 2.6rem;
  line-height: 3.625rem;
  letter-spacing: -0.015em;
  width: 90%;
  color: rgb(16, 15, 15);
}
.hero-description {
  font-family: "Work Sans", sans-serif;
  color: #596080;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.knowmore {
  margin: 0.5rem;
  padding: 0.75rem 1.44rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-radius: 12px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: 0px;
  box-shadow: 1px 0px 17px 1px black;
  transition: all 0.5s;
}
.knowmore:hover {
  box-shadow: 0px 0px 0px 1px black;
}
.knowmore span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.knowmore span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.knowmore:hover span {
  padding-right: 25px;
}

.knowmore:hover span:after {
  opacity: 1;
  right: 0;
}
.hero-box-image {
  margin-top: 3rem;
  height: auto;
  max-width: 100%;
}
.btn-ta-logo {
  height: 2rem;
}
.booked {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #ba181b;
}
.allroom {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin: 1rem 2.5rem;
  padding: 7px;
  height: 3.5rem;
  position: relative;
  background-color: #553f3f12;
}
.allroom h1 {
  font-size: 30px;
}

@media (max-width: 1024px) {
  .hero-container {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-text-content,
  .hero-image-box {
    width: 100%;
  }

  .hero-heading {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 100%;
  }

  .hero-description {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .allroom h1 {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  .hero-heading {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .hero-description {
    font-size: 0.675rem;
    line-height: 1rem;
  }

  .knowmore {
    font-size: 1rem;
    line-height: 1rem;
    margin: 0.5rem;
  }
  .allroom {
    height: 2rem;
  }
  .allroom h1 {
    font-size: 14px;
  }
}
