.admin-add-rooms {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}

.add-room-container {
  display: flex;
}

.form-selector {
  border: 1px solid black;
}

.all-rooms-link {
  text-decoration: none;
}
