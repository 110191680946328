.nav {
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background-color: #000;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.8rem;
}
.nav-logo-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.nav-links-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.nav-link-itoms {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.nav-link-itoms::after {
  content: "";
  width: 0;
  height: 0.375rem;
  border-radius: 3.125rem;
  margin: auto;
}
.nav-link-itoms:hover::after {
  width: 100%;
  transition: width 0.3s linear;
}
.nav-logo {
  height: 40px;
  width: auto;
}

.title {
  font-family: "Raleway", sans-serif;
  margin-bottom: 0.75rem;
  color: inherit;
  text-decoration: none;
  font-size: 2rem;
  justify-content: left;
  margin-top: 0.3rem;
}

.linkaa {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  font-size: 1.25rem;
  align-items: center;
}

.linkaa::after {
  content: "";
  width: 0;
  height: 0.375rem;
  border-radius: 3.125rem;
  margin: auto;
  display: block;
}

.linkaa:hover::after {
  width: 100%;
  transition: width 0.3s linear;
}
.link-content-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.span_logo {
  height: 125px;
  width: 125px;
}

.logout-icon {
  font-size: 1.5rem;
  color: #ff7f7f;
}
.floorplan-logo {
  font-size: 1.5rem;
  margin-right: 0.3125rem;
}

@media screen and (max-width: 768px) {
  .nav-logo {
    height: 25px;
    width: auto;
  }
  .title {
    font-size: 1.5rem;
  }

  .linkaa {
    font-size: 1rem;
  }

  .span_logo {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .title {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .linkaa {
    font-size: 0.675rem;
    color: white;
  }

  .span_logo {
    font-size: 1rem;
  }
}
.nav-modal-container {
  font-family: "Lato", sans-serif;
  color: #333333;
}
.nav-modal-footer {
  justify-content: space-between;
}
