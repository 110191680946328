.room-booking-history-btn {
  border: none;
}
.edit-delete-btn {
  display: flex;
  justify-content: end;
  gap: 1rem;
}
.edit-delete-btn button {
  border: none;
  font-size: 23px;
  gap: 15px;
}

.hispagination_admin {
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.hisbuttonnxt_admin,
.hisbuttonpre_admin {
  background-color: #313131;
  color: white;
  margin: 0 2rem;
}

@media (max-width: 1440px) {
  .hispagination_admin {
    margin-top: 1rem;
  }

  .hisbuttonnxt_admin,
  .hisbuttonpre_admin {
    margin: 0 1rem;
  }
}

@media (max-width: 1280px) {
  .hispagination_admin {
    margin-top: 1.5rem;
  }

  .hisbuttonnxt_admin,
  .hisbuttonpre_admin {
    margin: 0 0.5rem;
  }
}

@media (max-width: 1152px) {
  .hispagination_admin {
    margin-top: 1rem;
  }

  .hisbuttonnxt_admin,
  .hisbuttonpre_admin {
    margin: 0 0.2rem;
  }
}
