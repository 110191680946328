.history-container {
  padding: 0 2rem;
}
.history-heading-box {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: center;
  padding: 15px 15px;
  height: 3.5rem;
  background-color: #553f3f12;
}
.history-heading h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 30px;
  width: 100%;
}



