.room-name-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cancel-btn {
  background-color: transparent;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.room-booking-form-card {
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #6e6e6e;
  border-radius: 15px;
  margin: 4rem auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 2px 5px #313131;
}
.booking-form-heading {
  font-family: "Raleway", sans-serif;
  text-decoration: none;
  font-weight: 700;
  font-size: 25px;
  float: left;
  margin: 0;
}
.create-room-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}
.create-room-form-box {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
}
.form-label-text {
  color: #6b778c;
  font-size: 18px;
  font-weight: 500;
  width: 130px;
}
.form-input-box {
  flex-grow: 1;
}
.booking-form-save-btn button {
  float: right;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin-top: 10px;
  width: 25%;
  height: fit-content;
  padding-bottom: 8px;
  padding-top: 6px;
  font-size: 0.9rem;
  border: none;
  border-radius: 10px;
  background-color: #313131;
  color: white;
}
.booking-form-save-btn button:hover {
  background-color: #000;
}
.view-meeting-history-btn {
  width: 100%;
}

.room-booking-form {
  background: padding-box;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
}

.search-wrapper {
  max-width: 270px;
  overflow-y: auto;
  height: 70px;
  min-height: 0;
}

@media (max-width: 768px) {
  .room-booking-form-card {
    padding: 20px;
    margin: 2rem auto;
  }

  .booking-form-heading {
    font-size: 20px;
  }

  .form-label-text {
    font-size: 16px;
    width: 100px;
  }

  .booking-form-save-btn button {
    width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .room-booking-form-card {
    padding: 15px;
  }

  .booking-form-heading {
    font-size: 18px;
  }

  .form-label-text {
    font-size: 14px;
    width: 80px;
  }
  .multi-select-container {
    width: 75%;
  }
}
