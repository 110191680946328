.login-container {
  width: 100%;
  margin-top: 8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.signin-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.ta-logo {
  max-height: 80px;
  max-width: 80px;
}
.login-title {
  width: 70%;
  text-align: center;
  color: black;
  font-size: 2.5rem;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
.google-login-btn {
  font-family: "Raleway", sans-serif;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 0px 0px 10px 0px;
  padding: 0.6rem;
  font-size: 1.5rem;
  border: none;
  color: #343a40;
  background-color: white;
}
.google-login-btn:hover {
  background-color: #313131;
  color: white;
}

.image-container {
  border-radius: 15px;
  background: url(../../../assets/images/loginmain_bg.jpg);
  background-size: cover;
  width: auto;
}

.login-bg-image {
  margin-left: 8%;
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: 15px;
}

.redirect-page {
  margin-top: 2rem;
  text-align: center;
  color: rgb(52, 52, 52);
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
.redirect-link {
  color: blue;
  text-decoration: none;
}
@media (max-width: 1024px) {
  .login-container {
    margin-top: 2rem;
    flex-direction: column;
    gap: 3rem;
  }
  .login-title {
    font-size: 2rem;
  }
  .login-bg-image {
    height: auto;
    width: 80%;
  }
}
@media (max-width: 768px) {
  .login-container {
    margin-top: 1rem;
    margin-top: 1.5rem;
    gap: 2rem;
  }
  .login-title {
    font-size: 2rem;
  }
  .google-login-btn {
    width: 90%;
    box-shadow: 0px 0px 10px 0px;
    font-size: 1.3rem;
    border: none;
    color: #343a40;
    background-color: white;
  }
  .login-bg-image {
    height: auto;
    width: 80%;
  }
}

@media (max-width: 576px) {
  .login-container {
    margin-top: 1rem;
    gap: 2rem;
  }
  .login-title {
    font-size: 1.8rem;
  }
  .image-container {
    margin-left: 0;
    width: 90%;
  }

  .login-bg-image {
    height: auto;
    width: 80%;
  }
}
